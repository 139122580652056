import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserActions, UserSelectors } from 'pages-lib';
import { Observable } from 'rxjs';
import { selectEmtelliproServer } from './shared/app/app-state/store/app-state.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'visual-client';
  username$: Observable<string>;
  server$: Observable<string>;
  loggedIn$: Observable<boolean>;
  commitId: string;
  timeStamp: string;

  constructor(private store: Store, @Inject('env') private env: any) {
    this.username$ = this.store.select(UserSelectors.selectUsername);
    this.server$ = this.store.select(selectEmtelliproServer);
    this.loggedIn$ = this.store.select(UserSelectors.selectLoggedIn);
    this.commitId = env.commitId;
    this.timeStamp = env.timeStamp;
  }

  onLogout() {
    this.store.dispatch(UserActions.signOut());
  }
}
