<lib-dialog></lib-dialog>
<lib-snackbar></lib-snackbar>
<lib-header [username]="username$ | async" [userToolTip]="server$ | async" [homepage]="'/text-entry'" [loggedIn]="loggedIn$ | async"
    (onLogout)="onLogout()">
    <span class="sub-brand">Visual Client <span class="release beta">beta</span></span>
</lib-header>
<div class="page">
    <section class="content">
        <router-outlet></router-outlet>
    </section>
    <div class="footer">
        <small>
            ©2021 by emtelligent Vancouver, Canada
        </small>
        <small>
            v{{timeStamp}}-{{commitId.substr(0, 6)}}
        </small>
    </div>
</div>