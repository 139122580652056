import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NLPOutput } from '../../output-type';
import * as VisualizerActions from '../visualizer/visualizer.actions';
import * as OntologyActions from './ontologies.actions';
import { ontologiesFeatureKey } from './ontologies.reducer';


@Injectable()
export class OntologiesEffects {

  hydrateOntologies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Reports] Fetch Report Success'),
      switchMap((nlpOutput: NLPOutput) => {
        const loadOntologies = OntologyActions.loadOntologies({
          ids: this.getOntologies(nlpOutput)
        });
        const notifyLoadingDone = VisualizerActions.storeHydrated({
          storeType: ontologiesFeatureKey
        });
        return of(loadOntologies, notifyLoadingDone);
      })
    );
  });

  clear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Reports] Cancel Fetch Report'),
      switchMap(() => of(OntologyActions.clearOntologies()))
    );
  });

  constructor(private actions$: Actions) { }

  private getOntologies(nlpOutput: NLPOutput) {
    return Object.keys(nlpOutput.documents[0].concepts);
  }

}
