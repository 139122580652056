import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EntityComponent } from './entity/entity.component';
import { RelationComponent } from './relation/relation.component';
import { SectionComponent } from './section/section.component';
import { SelectorsModule } from './selectors/selectors.module';
import * as fromVisualizer from './store';
import { VisualizerComponent } from './visualizer.component';
import { EffectsModule } from '@ngrx/effects';
import { EntityEffects } from './store/entity/entity.effects';
import { SectionEffects } from './store/section/section.effects';
import { ConceptEffects } from './store/concept/concept.effects';
import { OntologiesEffects } from './store/ontologies/ontologies.effects';
import { RelationEffects } from './store/relation/relation.effects';
import { VisualizerEffects } from './store/visualizer/visualizer.effects';
import { MetaModule } from './meta/meta.module';
import { EntityGroupComponent } from './entity-group/entity-group.component';
import { RectDirective } from './directives/rect/rect.directive';
import { EntityDirective } from './directives/entity/entity.directive';
import { RelationDirective } from './directives/relation/relation.directive';
import { OptionsBarModule } from './options-bar/options-bar.module';
// import { TopNavModule } from 'elements-lib';
import { SpanEffects } from './store/span/span.effects';
import { LabelDirective } from './directives/label/label.directive';
import { TopNavModule } from 'projects/elements-lib/src/lib/top-nav/top-nav.module';
import { RelationLabelsComponent } from './relation-labels/relation-labels.component';


@NgModule({
  declarations: [
    VisualizerComponent,
    SectionComponent,
    EntityComponent,
    RelationComponent,
    EntityGroupComponent,
    RectDirective,
    EntityDirective,
    RelationDirective,
    LabelDirective,
    RelationLabelsComponent,
  ],
  imports: [
    CommonModule,
    TopNavModule,
    SelectorsModule,
    MetaModule,
    OptionsBarModule,
    StoreModule.forFeature(fromVisualizer.visualizerFeatureKey, fromVisualizer.reducers, { metaReducers: fromVisualizer.metaReducers }),
    EffectsModule.forFeature([EntityEffects, SectionEffects, ConceptEffects, OntologiesEffects, RelationEffects, VisualizerEffects, SpanEffects])
  ],
  exports: [
    VisualizerComponent
  ]
})
export class VisualizerModule { }
