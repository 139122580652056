<div *ngIf="entityType$ | async as entityType" class="frame" [ngStyle]="{
    'top': (span.y + span.height) + 'px',
    'left' : span.x + 'px',
    'min-width': span.width + 'px',
    'max-width': ((highlighted$ | async)? '2000%': span.width + 'px')
    }" [ngClass]="{'highlighted':highlighted$ | async}">
    <ng-container *ngIf="(entity$ | async) as entity" #metaElement>
        <div class="excerpt">
            <span class="entity-type">{{entity.id}}: {{ entityType }}</span>
            <span class="rounded-corners">
                <span *ngIf="entity.attributes.question_status === 'is_question'" class="question-status">Q</span>
                <span *ngIf="entity.attributes.uncertainty === 'uncertain'" class="uncertain">U</span>
            </span>
        </div>
        <div class="full">
            <span>{{entity.attributes.uncertainty}}{{entity.attributes.polarity && entity.attributes.uncertainty ? '/':''}}{{entity.attributes.polarity}}</span>
            <ng-container *ngIf="(selectedConcept$ | async) as selectedConcept">
                <span class="description">{{ selectedConcept.description }}</span>
                <span class="description">ID: {{ selectedConcept.concept_id }}</span>
            </ng-container>
        </div>
    </ng-container>
</div>