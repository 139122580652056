import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map, withLatestFrom } from 'rxjs/operators';
import { RelationsService } from '../services/relations.service';
import { selectHighlightedSpans } from '../store/entity/entity.reducer';
import { Relation } from '../store/relation/relation.model';
import { selectDisabledRelations, selectVisibleRelations } from '../store/relation/relation.reducer';
import { selectSpanEntities, State } from '../store/span/span.reducer';
import { Line } from './line/line';
import { LineBounds, Point } from './relation.types';



@Component({
  selector: 'app-relation',
  templateUrl: './relation.component.html',
  styleUrls: ['./relation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelationComponent implements OnInit {
  @Input()
  mouse: Point;

  lineHeight = 8;
  vShift = -8;
  roundness = 4;
  padding = 12;
  arrowSize = 4;

  arrowDown = `-${this.arrowSize},0 ${this.arrowSize},0 0,${this.arrowSize * 2}`;
  arrowRight = `-${this.arrowSize},-${this.arrowSize} ${this.arrowSize},0 -${this.arrowSize},${this.arrowSize}`;
  arrowLeft = `-${this.arrowSize},0 ${this.arrowSize},${this.arrowSize} ${this.arrowSize},-${this.arrowSize}`;
  relations$: Observable<Relation[]>;
  lines$: Observable<Line[]>;
  labels$: Observable<any[]>;
  dockCellSize: number;
  relationPoints: Map<string, LineBounds>;
  highlightedRelations$: Observable<string[]>;
  highlightedSpans$: Observable<string[]>;


  constructor(private relationsService: RelationsService, private store: Store<State>, private host: ElementRef) {
    this.relationPoints = new Map<string, LineBounds>();
    this.dockCellSize = this.arrowSize * 3;
  }

  ngOnInit(): void {
    const relations$ = this.store.select(selectVisibleRelations);
    this.lines$ = this.relationsService.lines$;
    this.labels$ = this.relationsService.labels$;
    this.highlightedSpans$ = this.store.select(selectHighlightedSpans);
    this.highlightedRelations$ = this.highlightedSpans$.pipe(
      withLatestFrom(relations$),
      map(([highlightedSpans, relations]) => {
        return relations
          .filter(relation => {
            return this.intersectingArrays(highlightedSpans, relation.from)
              || this.intersectingArrays(highlightedSpans, relation.to);
          })
          .map(relation => {
            return relation.id;
          })
      })
    );
  }

  isHighlighted(line: Line, highlightedRelations: string[], highlightedSpans: string[]) {
    const relationId = line.id;
    const isAtStart = highlightedSpans.includes(line.startSpan.id);
    const isAtEnd = highlightedSpans.includes(line.endSpan.id);
    const isRelevant = highlightedRelations.includes(relationId)
      && (isAtStart || isAtEnd)
    if (!isRelevant) {
      return false;
    }
    if (highlightedRelations.length === 1) {
      return true;
    }
    if (isAtStart) {
      const distance = Math.abs(this.mouse.x - line.start.x)
      return distance < 4;
    }
    const distance = Math.abs(this.mouse.x - line.end.x);
    return distance < 4;

  }

  trackLabels(index: number, { key, value }: { key: string, value: string }) {
    return `${key}:${value}`;
  }

  private intersectingArrays<T>(a: T[], b: T[]): boolean {
    if (a.length === 0 && b.length === 0) {
      return false;
    }
    return b.some(lastIntersection => a.indexOf(lastIntersection) !== -1);
  }

  identify(index: number, line: Line) {
    return `${line.id}_${line.startSpan.id}_${line.endSpan.id}`;
  }
}
