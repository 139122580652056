import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import type { NavElement } from 'projects/elements-lib/src/lib/top-nav/nav-element';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectRawResult } from '../shared/reports/store/reports.selectors';
import { NLPOutput } from './output-type';
import { State } from './store';
import * as OntologyActions from './store/ontologies/ontologies.actions';
import { getAllOntologies } from './store/ontologies/ontologies.reducer';
import { Section } from './store/section/section.model';
import * as SectionReducers from './store/section/section.reducer';
import { clearHydratedStores } from './store/visualizer/visualizer.actions';
import ResizeObserver from 'resize-observer-polyfill';

@Component({
  selector: 'app-visualizer',
  templateUrl: './visualizer.component.html',
  styleUrls: ['./visualizer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisualizerComponent implements OnInit, AfterViewInit, OnDestroy {

  sections$: Observable<Section[]>;
  ontologies$: Observable<NavElement[]>;
  rawResult$: Observable<NLPOutput>;
  mouse: { x: number; y: number; };

  @ViewChild('visRoot')
  visRootEl: ElementRef<HTMLDivElement>;
  private left: number;
  private top: number;
  observer: ResizeObserver;

  constructor(private store: Store<State>, private sanitizer: DomSanitizer) {
    this.left = 0;
    this.top = 0;
    this.observer = new ResizeObserver((mutations) => {
      const {
        offsetLeft: left,
        offsetTop: top
      } = mutations[0].target as HTMLElement;
      this.left = left;
      this.top = top;
    });
  }

  ngOnInit(): void {
    this.store.dispatch(clearHydratedStores());
    this.sections$ = this.store.select(SectionReducers.selectAllSections).pipe(
      map(sections => {
        return sections.filter(section => section.level === 0)
      })
    );
    this.ontologies$ = this.selectOntologies();
    this.rawResult$ = this.store.select(selectRawResult)
      .pipe(
        map(raw => JSON.parse(raw))
      );
  }

  ngAfterViewInit(): void {
    this.observer.observe(this.visRootEl.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  @HostListener('mousemove', ['$event.x', '$event.y', '$event'])
  onMouseMove(x: number, y: number, event: MouseEvent) {
    this.mouse = {
      x: event.clientX - this.left,
      y: event.clientY - this.top
    };
  }

  onOntologyChange(ontologyId: string) {
    this.store.dispatch(OntologyActions.selectOntology({
      selected: ontologyId
    }));
  }

  makeJsonHrefFile(raw: NLPOutput) {
    const spacing = 4;
    const data = `data:text/json;charset=utf-8,${JSON.stringify(raw, null, spacing)}`;
    return this.sanitizer.bypassSecurityTrustUrl(data);
  }

  private selectOntologies() {
    return this.store.select(getAllOntologies)
      .pipe(
        map(ontologies => {
          return ontologies.map(o => {
            const navElement: NavElement = {
              id: o,
              label: o
            };
            return navElement;
          });
        })
      );
  }

}
