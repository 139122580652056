import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map, takeWhile, tap, withLatestFrom } from 'rxjs/operators';
import { State } from '../../store';
import { selectAllEntities, selectDisabledTypes, selectHighlightedSpans } from '../../store/entity/entity.reducer';
import { getSelectedOntology } from '../../store/ontologies/ontologies.reducer';
import { Relation } from '../../store/relation/relation.model';
import { selectVisibleRelations } from '../../store/relation/relation.reducer';

@Directive({
  selector: '[appEntity]'
})
export class EntityDirective {

  @Input()
  ids: string[];

  alive: boolean;

  constructor(public el: ElementRef, private renderer: Renderer2, private store: Store<State>) {
    this.alive = true;
  }
  ngOnDestroy(): void {
    this.alive = false;
  }
  ngAfterViewInit(): void {
    if (this.ids.length === 0) {
      return;
    }
    const textContent = (this.el.nativeElement as HTMLElement).textContent;
    const relations$ = this.store.select(selectVisibleRelations)
      .pipe(
        map(relations => {
          const fromRelations: Relation[] = [];
          const toRelations: Relation[] = [];
          relations.forEach(relation => {
            if (relation.from.some(from => this.ids.includes(from))) {
              fromRelations.push(relation);
            }
            if (relation.to.some(from => this.ids.includes(from))) {
              toRelations.push(relation);
            }
          });
          return [fromRelations, toRelations];
        })
      );

    const activeIds$ = combineLatest([
      this.store.select(getSelectedOntology),
      this.store.select(selectAllEntities)])
      .pipe(

        map(([selectedOntology, entities]) => {
          return entities.reduce<string[]>((activeEntities, entity) => {
            const entityTypes = Object.keys(entity.entity_type);
            if (entityTypes.includes(selectedOntology)) {
              activeEntities.push(entity.id);
            }
            return activeEntities;
          }, []);
        })
      );


    const highlighted$ = this.store.pipe(
      select(selectHighlightedSpans),
      distinctUntilChanged((a, b) => this.equalArrays(a, b)),
      withLatestFrom(activeIds$),
      map(([highlightedSpans, activeIds]) => {
        const localActiveIds = this.ids.filter(id => activeIds.includes(id));
        return this.intersectingArrays(highlightedSpans, localActiveIds);
      })
    );

    const entities$ = this.store.pipe(
      select(selectAllEntities),
      map(allEntities => {
        return allEntities.filter(entity => {
          return this.ids.includes(entity.id)
        })
      })
    );

    const selectedOntology$ = this.store.select(getSelectedOntology);
    const disabledTypes$ = this.store.select(selectDisabledTypes);
    const activeEntities$ = combineLatest([
      entities$,
      selectedOntology$,
      disabledTypes$
    ]).pipe(
      map(([entities, ontology, disabledTypes]) => {
        return entities.filter(entity => {
          const activeEntityType = entity.entity_type[ontology];
          const isWithinTheSelectedOntology = !!activeEntityType;
          const isDisabledType = disabledTypes.includes(activeEntityType);
          return isWithinTheSelectedOntology && !isDisabledType;
        })
      })
    )

    relations$.pipe(
      tap(([fromRelations, toRelations]) => {
        const relationCount = fromRelations.length + toRelations.length;
        const hasRelation = relationCount > 0;
        const isEmpty = textContent.length === 0;
        const isWhitespace = !!textContent.match(/^\s*$/);
        if (hasRelation && !isEmpty) {
          this.renderer.addClass(this.el.nativeElement, 'has-relation');
          if (!isWhitespace) {
            if (relationCount > 1) {
              this.renderer.setStyle(this.el.nativeElement, 'min-width', `${(relationCount + 2) * 10}px`);
            }
            this.renderer.setStyle(this.el.nativeElement, 'margin-top', `${(relationCount + 2) * 4 * 2}px`);
            this.renderer.setStyle(this.el.nativeElement, 'margin-bottom', `${(relationCount + 2) * 4 * 2}px`);
          }
        }
      }),
      takeWhile(() => this.alive)
    ).subscribe();

    highlighted$.pipe(
      tap(highlighted => {
        if (highlighted) {
          this.renderer.addClass(this.el.nativeElement, 'highlighted');
        } else {
          this.renderer.removeClass(this.el.nativeElement, 'highlighted');
        }
      }),
      takeWhile(() => this.alive)
    ).subscribe();

    entities$.pipe(
      tap(entities => {
        entities.forEach(entity => {
          this.renderer.addClass(this.el.nativeElement, entity.attributes.polarity);
          this.renderer.addClass(this.el.nativeElement, entity.attributes.uncertainty);
        });
      }),
      takeWhile(() => this.alive)
    ).subscribe();

    activeEntities$.pipe(
      tap(activeEntities => {
        if (activeEntities.length > 0) {
          this.renderer.addClass(this.el.nativeElement, 'visible');
        } else {
          this.renderer.removeClass(this.el.nativeElement, 'visible');
        }

      }),
      takeWhile(() => this.alive)

    ).subscribe()
  }

  private intersectingArrays<T>(a: T[], b: T[]): boolean {
    if (a.length === 0 && b.length === 0) {
      return false;
    }
    return b.some(lastIntersection => a.indexOf(lastIntersection) !== -1);
  }

  private equalArrays<T>(a: T[], b: T[]) {
    if (a.length !== b.length) {
      return false;
    }
    for (const aItem of a) {
      if (!b.includes(aItem)) {
        return false;
      }
    }
    return true;
  }
}
