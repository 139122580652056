import { state, style, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavElement } from '../nav-element';
import { NavEvent } from '../nav-event';

@Component({
  selector: 'lib-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.scss'],
  animations: [
    trigger('activation', [
      state('active', style({
        color: 'white',
        borderBottom: '4px #fec953 solid'
      }))
    ])
  ]
})
export class TopNavBarComponent implements OnInit {
  active: string;

  @Input()
  buttons: NavElement[];

  @Input()
  set default(value: string) {
    if (value !== this._default) {
      this.setSelected();
      this._default = value;
    }
  }

  @Output()
  onChange = new EventEmitter<string>();

  private _default: string;

  constructor() { }

  ngOnInit(): void {
    this.setSelected();
  }

  onButtonClick(event: NavEvent) {
    this.active = event.id;
    this.onChange.emit(event.id);
  }

  setSelected() {
    if (this.buttons.length > 0) {
      const defaultIndex = this.buttons.findIndex(b => b.id === this._default);
      const selected = this.buttons[defaultIndex !== -1 ? defaultIndex : 0].id;
      this.active = selected;
      this.onChange.emit(selected);
    }
  }
}
