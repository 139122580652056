import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Relation } from './relation.model';
import * as RelationActions from './relation.actions';
import { selectAllEntities, selectEntityIds } from '../entity/entity.reducer';

export const relationsFeatureKey = 'relations';

export interface State extends EntityState<Relation> {
  disabledTypes: string[];
}

export const adapter: EntityAdapter<Relation> = createEntityAdapter<Relation>();

export const initialState: State = adapter.getInitialState({
  disabledTypes: []
});


export const reducer = createReducer(
  initialState,
  on(RelationActions.addRelation,
    (state, action) => adapter.addOne(action.relation, state)
  ),
  on(RelationActions.upsertRelation,
    (state, action) => adapter.upsertOne(action.relation, state)
  ),
  on(RelationActions.addRelations,
    (state, action) => adapter.addMany(action.relations, state)
  ),
  on(RelationActions.upsertRelations,
    (state, action) => adapter.upsertMany(action.relations, state)
  ),
  on(RelationActions.updateRelation,
    (state, action) => adapter.updateOne(action.relation, state)
  ),
  on(RelationActions.updateRelations,
    (state, action) => adapter.updateMany(action.relations, state)
  ),
  on(RelationActions.deleteRelation,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(RelationActions.deleteRelations,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(RelationActions.loadRelations,
    (state, action) => adapter.setAll(action.relations, state)
  ),
  on(RelationActions.clearRelations,
    state => adapter.removeAll(state)
  ),
  on(RelationActions.toggleRelationType,
    (state, { relationType }) => {
      const disabledTypes = new Set(state.disabledTypes);
      if (disabledTypes.has(relationType)) {
        disabledTypes.delete(relationType);
      } else {
        disabledTypes.add(relationType);
      }
      return {
        ...state,
        disabledTypes: [...disabledTypes.values()]
      }
    }),
  on(RelationActions.toggleAllRelationTypes, (state, { disableAllTypes }) => {
    if (disableAllTypes) {
      const ids = [...state.ids.values()] as string[];
      const allEntities = ids.map<Relation>(id => state.entities[id]);
      const allTypes = collectAllTypes(allEntities);
      return {
        ...state,
        disabledTypes: [...allTypes.keys()]
      }
    }
    return {
      ...state,
      disabledTypes: []
    }
  })
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

const collectAllTypes = (relations: Relation[]): Set<string> => {
  return relations.reduce<Set<string>>((types, relation) => {
    types.add(relation.type);
    return types;
  }, new Set<string>());
}

export const getRelationsState = createFeatureSelector<State>(relationsFeatureKey);
export const selectAllRelations = createSelector(getRelationsState, selectAll);
export const selectVisibleRelations = createSelector(
  selectAllRelations,
  selectEntityIds,
  (relations: Relation[], ids: string[]) => {
    return relations.filter(relation => {
      const hasFrom = relation.from.every(from => ids.includes(from));
      const hasTo = relation.to.every(to => ids.includes(to));
      return hasFrom && hasTo;
    });
  }
);

export const selectRelationTypes = createSelector(
  selectAllRelations,
  relations => {
    return collectAllTypes(relations);
  }
);

export const selectDisabledRelations = createSelector(getRelationsState, state => state.disabledTypes);
export const selectAllRelationsDisabled = createSelector(
  selectRelationTypes,
  selectDisabledRelations, (allTypes, disabledTypes) => {
    const types = [...allTypes.keys()];
    if (types.length === 0 || disabledTypes.length === 0) {
      return false;
    }
    return types.every(entityType => disabledTypes.includes(entityType));
  }
);

