import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, timer } from 'rxjs';
import { delayWhen, map, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { AdvancedOptionsState } from '../../pages/text-entry/advanced-options/advanced-options.component';
import { NLPOutput } from '../../visualizer/output-type';
import { selectEmtelliproServer } from '../app/app-state/store/app-state.selectors';
import { sendReportFail, updateProcessReportStatus } from './store/reports.actions';
import { State } from './store/reports.reducer';

interface SubmitReportResult {
  id: string;
  task_id: string;
};

interface CheckStatusResult {
  completion: {
    completed: number,
    total: number
  },
  state: ProcessingState
}

export enum ProcessingState {
  SETUP = 'setup',
  PROCESSING = 'processing',
  FAILURE = 'failure',
  CANCELLED = 'cancelled',
  SUCCESS = 'success',
  ERROR = 'error'
}

@Injectable({
  providedIn: 'root',
})
export class ReportsService {

  emtelliproServer$: Observable<string>;
  host: string;


  constructor(private http: HttpClient, private store: Store<State>) {
    this.emtelliproServer$ = this.store.select(selectEmtelliproServer);
  }

  validateURl(host: string) {
    return this.http.get(`${host}/emtellipro/user`)
  }

  sendReport(reportText: string, file: File, category: string, subcategory: string, advancedOptions?: AdvancedOptionsState) {
    return this.emtelliproServer$.pipe(
      take(1),
      switchMap(host => {
        const fileName = file ? file.name : 'visual-client';
        const form = new FormData();
        form.set(`category[${fileName}]`, category);
        form.set(`subcategory[${fileName}]`, subcategory);
        form.set(`id[${fileName}]`, fileName);
        if (file) {
          form.set(`type[${fileName}]`, 'default');
          form.set(`file[${fileName}]`, file, fileName);
        }
        if (reportText) {
          form.set(`type[${fileName}]`, 'plain');
          form.set(`file[${fileName}]`, new Blob([reportText], {
            type: 'text/plain'
          }), fileName);
        }
        const url = `${host}/emtellipro/submit`;
        const options = advancedOptions.enabled ? {
          params: {
            features: this.selectEnabledFeatures(advancedOptions).join(',')
          }
        } : undefined;
        return this.http.post<SubmitReportResult>(url, form, options);
      })
    );
  }

  selectEnabledFeatures(advancedOptions: AdvancedOptionsState): string[] {
    return advancedOptions.ids.reduce<string[]>((enabledFeatures, featureId) => {
      const feature = advancedOptions.entities[featureId];
      if (feature.value === true) {
        enabledFeatures.push(feature.option);
      }
      return enabledFeatures;
    }, []);
  }

  pollProcessingStatus(taskId: string) {
    return this.emtelliproServer$.pipe(
      take(1),
      switchMap(host => {
        const url = `${host}/emtellipro/status/${taskId}?timeout=10`;
        let retries = 0;
        return this.http.get<CheckStatusResult>(url).pipe(
          tap(result => {
            this.store.dispatch(updateProcessReportStatus({
              taskId,
              state: result.state
            }));
          }),
          map(result => {
            switch (result.state) {
              case ProcessingState.SUCCESS:
                return {
                  ...result,
                  taskId
                }
              case ProcessingState.SETUP:
              case ProcessingState.PROCESSING:
                throw new Error('Not done processing');
              default:
                break;
            }
            this.store.dispatch(sendReportFail({
              error: {
                message: 'Failed to process report'
              }
            }));
          }),
          retryWhen(errors => {
            return errors.pipe(
              tap(() => {
                retries++;
              }),
              delayWhen(() => timer(retries * 1000)),
              take(100)
            );
          })
        );
      }));
  }

  getReportResults(taskId: string) {
    return this.emtelliproServer$.pipe(
      take(1),
      switchMap(host => {
        const url = `${host}/emtellipro/result/${taskId}?format=emtellipro-json-2`;
        return this.http.get<NLPOutput>(url);
      }));
  }
}
