import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterNavigatedPayload, ROUTER_NAVIGATED, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { of } from 'rxjs';
import { filter, map, pairwise, switchMap, tap } from 'rxjs/operators';
import { cancelReport } from '../reports/store/reports.actions';
import { UserActions } from 'pages-lib';


@Injectable()
export class RouterEffectsEffects {

  clearReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      filter((action: RouterNavigatedPayload) => {
        const url = action?.event?.url;
        return url && (url === '/text-entry' || url === '/sign-in')
      }),
      switchMap(() => of(cancelReport()))
    );
  });

  navigateHome$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.userLoaded),
      tap(() => {
        if(this.router.url === '/sign-in'){
          this.router.navigate([this.env.homePath]);
        }
      })
    );
  }, { dispatch: false });

  exitOnSignOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.signOut),
      tap(() => {
        this.router.navigate(['/']);
      })
    );
  }, { dispatch: false });

  constructor(
    private actions$: Actions, 
    private router: Router,
    @Inject('env') private env: any) { }

}
