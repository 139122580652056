import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NavElement } from '../nav-element';
import { NavEvent } from '../nav-event';

@Component({
  selector: 'lib-top-nav-button',
  templateUrl: './top-nav-button.component.html',
  styleUrls: ['./top-nav-button.component.scss']
})
export class TopNavButtonComponent implements OnInit {

  @Input()
  navElement: NavElement;
  @Output()
  clickEvent = new EventEmitter<NavEvent>();

  constructor(private hostElement: ElementRef) { }

  ngOnInit(): void {
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    this.clickEvent.emit({
      id: this.navElement.id,
      element: this.hostElement
    });
  }

}
