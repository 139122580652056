import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Container, DialogService, SnackbarService } from 'elements-lib';
import { LoginComponent, UserActions } from 'pages-lib';
import { mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { SessionExpiredComponent } from '../prompts/session-expired/session-expired.component';


@Injectable()
export class UserEffects {

  promptSessionExpired$ = createEffect(() => {
    let container: Container<any, any>;

    return this.actions$.pipe(
      ofType(UserActions.sessionExpired),
      switchMap(() => {
        const {
          container: _container,
          content
        } = this.snackbar.open(SessionExpiredComponent);
        container = _container;
        return content.signInClicked;
      }),
      switchMap(() => {
        container.dismiss();
        return container.onClose;
      }),
      tap(() => {
        const {
          container: _container,
        } = this.dialog.open(LoginComponent, { sticky: true });
        container = _container;
      }),
      switchMap(() => this.actions$.pipe(ofType(UserActions.signInSuccess))),
      tap(() => {
        container.close();
      })
    );
  }, {
    dispatch: false
  });


  clearLocalStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.signOut),
      tap(() => {
        this.snackbar.close();
      })
    );
  }, { dispatch: false });



  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private dialog: DialogService) { }

}
