<svg class="frame">
    <defs>
        <g id="start-point">
            <rect [attr.width]="arrowSize * 2" [attr.height]="arrowSize * 2" [attr.x]="- arrowSize"
                class="start-point" />
        </g>
        <g id="arrow-down">
            <polygon [attr.points]="arrowDown" class="arrow-down" />
        </g>
        <g id="arrow-right">
            <polygon [attr.points]="arrowRight" class="arrow-right" />
        </g>
        <g id="arrow-left">
            <polygon [attr.points]="arrowLeft" class="arrow-left" />
        </g>
    </defs>
    <ng-container *ngIf="highlightedRelations$ | async as highlightedRelations">
        <ng-container *ngIf="highlightedSpans$ | async as highlightedSpans">
            <g *ngFor="let rLine of lines$ | async; trackBy:identify" class="relation-line-group"
                [ngClass]="{'highlighted': isHighlighted(rLine , highlightedRelations, highlightedSpans) }">
                <path [attr.d]="rLine.path" />
                <use xlink:href="#arrow-down" [attr.x]="rLine.end.x" [attr.y]="rLine.end.y + rLine.height" />
                <use xlink:href="#start-point" [attr.x]="rLine.start.x" [attr.y]="rLine.start.y + rLine.height" />
                <use *ngFor="let eolArrow of rLine.offPageConnectors"
                    [attr.xlink:href]="eolArrow.direction == '>'? '#arrow-right':'#arrow-left'"
                    [attr.x]="eolArrow.position.x" [attr.y]="eolArrow.position.y" />
            </g>
        </ng-container>
    </ng-container>
</svg>