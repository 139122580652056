import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NLPOutput } from '../../output-type';
import * as VisualizerActions from '../visualizer/visualizer.actions';
import * as EntityActions from './entity.actions';
import { Entity } from './entity.model';
import { entitiesFeatureKey } from './entity.reducer';


@Injectable()
export class EntityEffects {

  hydrateEntities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Reports] Fetch Report Success'),
      switchMap((nlpOutput: NLPOutput) => {
        const entities = this.getEntities(nlpOutput).map(entity => {
          return {
            id: entity.label,
            ...entity
          } as Entity;
        });
        const loadEntities = EntityActions.loadEntities({ entities });
        const notifyLoadingDone = VisualizerActions.storeHydrated({
          storeType: entitiesFeatureKey
        });
        return of(loadEntities, notifyLoadingDone);
      })
    );
  });

  clear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('[Reports] Cancel Fetch Report'),
      switchMap(() => of(EntityActions.clearEntities()))
    );
  });



  constructor(private actions$: Actions) { }

  private getEntities(nlpOutput: NLPOutput) {
    return nlpOutput.documents[0].entities.found;
  }

}
