import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TopNavBarComponent } from './top-nav-bar/top-nav-bar.component';
import { TopNavButtonComponent } from './top-nav-button/top-nav-button.component';

@NgModule({
  declarations: [
    TopNavBarComponent,
    TopNavButtonComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule
  ],
  exports: [
    TopNavBarComponent,
  ]
})
export class TopNavModule { }
